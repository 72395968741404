/* New Typography CSS */
/* Heading 72 */

.heading-72-regular {
    @apply text-[72px] font-normal leading-[90px] tracking-[-1.61px];
}

.heading-72-medium {
    @apply text-[72px] font-medium leading-[90px] tracking-[-1.61px];
}

.heading-72-semibold {
    @apply text-[72px] font-semibold leading-[90px] tracking-[-1.61px];
}

.heading-72-bold {
    @apply text-[72px] font-bold leading-[90px] tracking-[-1.61px];
}

/* Heading 64 */
.heading-64-regular {
    @apply text-[64px] font-normal leading-[80px] tracking-[-1.43px];
}

.heading-64-medium {
    @apply text-[64px] font-medium leading-[80px] tracking-[-1.43px];
}

.heading-64-semibold {
    @apply text-[64px] font-semibold leading-[80px] tracking-[-1.43px];
}

.heading-64-bold {
    @apply text-[64px] font-bold leading-[80px] tracking-[-1.43px];
}

/* Heading 56 */
.heading-56-regular {
    @apply text-[56px] font-normal leading-[70px] tracking-[-1.25px];
}

.heading-56-medium {
    @apply text-[56px] font-medium leading-[70px] tracking-[-1.25px];
}

.heading-56-semibold {
    @apply text-[56px] font-semibold leading-[70px] tracking-[-1.25px];
}

.heading-56-bold {
    @apply text-[36px] font-bold leading-[45px] tracking-[-0.79px] lg:text-[56px] lg:leading-[70px] lg:tracking-[-1.25px];
}

/* Heading 48 */
.heading-48-regular {
    @apply text-[48px] font-normal leading-[60px] tracking-[-1.02px];
}

.heading-48-medium {
    @apply text-[48px] font-medium leading-[60px] tracking-[-1.02px];
}

.heading-48-semibold {
    @apply text-[48px] font-semibold leading-[60px] tracking-[-1.02px];
}

.heading-48-bold {
    @apply text-[48px] font-bold leading-[60px] tracking-[-1.02px];
}

/* Heading 40 */
.heading-40-regular {
    @apply text-[28px] font-normal leading-[42px] tracking-[-0.52px] lg:text-[40px] lg:leading-[50px] lg:tracking-[-0.89px];
}

.heading-40-medium {
    @apply text-[28px] font-medium leading-[42px] tracking-[-0.52px] lg:text-[40px] lg:leading-[50px] lg:tracking-[-0.89px];
}

.heading-40-semibold {
    @apply text-[28px] font-semibold leading-[42px] tracking-[-0.52px] lg:text-[40px] lg:leading-[50px] lg:tracking-[-0.89px];
}

.heading-40-bold {
    @apply text-[28px] font-bold leading-[42px] tracking-[-0.52px] lg:text-[40px] lg:leading-[50px] lg:tracking-[-0.89px];
}

/* Heading 36 */
.heading-36-regular {
    @apply text-[36px] font-normal leading-[45px] tracking-[-0.79px];
}

.heading-36-medium {
    @apply text-[36px] font-medium leading-[45px] tracking-[-0.79px];
}

.heading-36-semibold {
    @apply text-[36px] font-semibold leading-[45px] tracking-[-0.79px];
}

.heading-36-bold {
    @apply text-[36px] font-bold leading-[45px] tracking-[-0.79px];
}

/* Heading 28 */
.heading-28-regular {
    @apply text-[28px] font-normal leading-[42px] tracking-[-0.52px];
}

.heading-28-medium {
    @apply text-[28px] font-medium leading-[42px] tracking-[-0.52px];
}

.heading-28-semibold {
    @apply text-[28px] font-semibold leading-[42px] tracking-[-0.52px];
}

.heading-28-bold {
    @apply text-[28px] font-bold leading-[42px] tracking-[-0.52px];
}

/* Heading 24 */
.heading-24-regular {
    @apply text-[24px] font-normal leading-[36px] tracking-[-0.47px];
}

.heading-24-medium {
    @apply text-[24px] font-medium leading-[36px] tracking-[-0.47px];
}

.heading-24-semibold {
    @apply text-[24px] font-semibold leading-[36px] tracking-[-0.47px];
}

.heading-24-bold {
    @apply text-[24px] font-bold leading-[36px] tracking-[-0.47px];
}

/* Body 20 */
.body-20-regular {
    @apply text-[18px] lg:text-[20px] font-normal leading-[27px] lg:leading-[30px] tracking-[-0.26px] lg:tracking-[-0.33px];
}

.body-20-medium {
    @apply text-[18px] lg:text-[20px] font-medium leading-[27px] lg:leading-[30px] tracking-[-0.26px] lg:tracking-[-0.33px];
}

.body-20-semibold {
    @apply text-[18px] lg:text-[20px] font-semibold leading-[27px] lg:leading-[30px] tracking-[-0.26px] lg:tracking-[-0.33px];
}

.body-20-bold {
    @apply text-[18px] lg:text-[20px] font-bold leading-[27px] lg:leading-[30px] tracking-[-0.26px] lg:tracking-[-0.33px];
}

/* Body 18 */

.body-18-regular {
    @apply text-[16px] font-normal leading-[24px] tracking-[-0.18px] lg:text-[18px] lg:leading-[27px] lg:tracking-[-0.26px];
}

.body-18-medium {
    @apply text-[16px] font-medium leading-[24px] tracking-[-0.18px] lg:text-[18px] lg:leading-[27px] lg:tracking-[-0.26px];
}

.body-18-semibold {
    @apply text-[16px] font-semibold leading-[24px] tracking-[-0.18px] lg:text-[18px] lg:leading-[27px] lg:tracking-[-0.26px];
}

.body-18-bold {
    @apply text-[16px] font-bold leading-[24px] tracking-[-0.18px] lg:text-[18px] lg:leading-[27px] lg:tracking-[-0.26px];
}

/* Body 16 */
.body-16-regular {
    @apply text-[14px] font-normal leading-[15px] tracking-[-0.11px] lg:text-[16px] lg:leading-[24px] lg:tracking-[-0.18px];
}

.body-16-medium {
    @apply text-[14px] font-medium leading-[15px] tracking-[-0.11px] lg:text-[16px] lg:leading-[24px] lg:tracking-[-0.18px];
}

.body-16-semibold {
    @apply text-[14px] font-semibold leading-[15px] tracking-[-0.11px] lg:text-[16px] lg:leading-[24px] lg:tracking-[-0.18px];
}

.body-16-bold {
    @apply text-[14px] font-bold leading-[15px] tracking-[-0.11px] lg:text-[16px] lg:leading-[24px] lg:tracking-[-0.18px];
}

/* Body 14 */
.body-14-regular {
    @apply text-[9px] font-normal leading-[12px] tracking-[-0.06px] lg:text-[14px] lg:leading-[20px] lg:tracking-[-0.09px];
}

.body-14-medium {
    @apply text-[9px] font-medium leading-[12px] tracking-[-0.06px] lg:text-[14px] lg:leading-[20px] lg:tracking-[-0.09px];
}

.body-14-semibold {
    @apply text-[9px] font-semibold leading-[12px] tracking-[-0.06px] lg:text-[14px] lg:leading-[20px] lg:tracking-[-0.09px];
}

.body-14-bold {
    @apply text-[9px] font-bold leading-[12px] tracking-[-0.06px] lg:text-[14px] lg:leading-[20px] lg:tracking-[-0.09px];
}

/* Label 12 */
.label-12-regular {
    @apply text-[12px] font-normal leading-[18px] tracking-[0.01px];
}

.label-12-medium {
    @apply text-[12px] font-medium leading-[18px] tracking-[0.01px];
}

.label-12-semibold {
    @apply text-[12px] font-semibold leading-[18px] tracking-[0.01px];
}

.label-12-bold {
    @apply text-[12px] font-bold leading-[18px] tracking-[0.01px];
}

/* existing CSS */

.h1 {
    @apply text-[64px] font-bold leading-[96px];
}

.h2 {
    @apply text-[48px] font-bold leading-[72px];
}

.h3 {
    @apply text-[32px] font-semibold leading-[48px];
}

.h4 {
    @apply text-[24px] font-bold leading-[36px];
}

.h5 {
    @apply text-[20px] font-bold leading-[30px];
}

.body1 {
    @apply text-[20px] leading-[30px];
}

.body2 {
    @apply text-[18px] leading-[27px];
}

.body3 {
    @apply text-[16px] leading-[24px];
}
.body4 {
    @apply text-[12px] leading-[18px];
}

.body5 {
    @apply text-[10px] leading-[15px];
}

@media (min-width: 640px) {
    .h1-responsive {
        @apply h1;
    }
    .h2-responsive {
        @apply h2;
    }

    .h4-responsive {
        @apply h4;
    }

    .body1-responsive {
        @apply body1;
    }

    .body2-responsive {
        @apply body2;
    }

    .body3-responsive {
        @apply body3;
    }
}

@media (max-width: 640px) {
    .h1-responsive {
        @apply h4;
    }

    .h2-responsive {
        @apply h4;
    }

    .h4-responsive {
        @apply body3;
    }

    .body1-responsive {
        @apply body3;
    }

    .body2-responsive {
        @apply body3;
    }

    .body3-responsive {
        @apply body4;
    }
}
